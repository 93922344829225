import {
  FormControl,
  FormGroup,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { globalFilterActions } from "../../../store/global-filter-slice";
import { httpContext } from "../../../lib/api/httpContext";
import {
  ButtonOutlined,
  SubmitButtonFilled,
} from "../Elements/button-elements";

const EventFilter = () => {
  const dispatch = useDispatch();
  const filterData = useSelector((state) => state.globalFilter);
  const [eventList, setEventList] = useState([]);
  const [hostList, setHostList] = useState([]);
  const eventNameInputRef = useRef("");
  const hostNameInputRef = useRef("");
  const [selectedEventId, setSelectedEventId] = useState();
  const [selectedHostId, setSelectedHostId] = useState();
  const [hostName, setHostName] = useState("");
  const [eventName, setEventName] = useState("");

  useEffect(() => {
    if (
      hostName.length < 3 &&
      hostName.length > 0 &&
      eventName.length < 3 &&
      eventName.length > 0
    ) {
      return;
    }

    const hostQuery = `?hostName=${hostName}`;
    const getHosts = async () => {
      const hostResponse = await httpContext(`hosts${hostQuery}`);
      if (hostResponse.ok) {
        const hostResponseData = await hostResponse.json();
        setHostList(hostResponseData);
      }
      if (!hostResponse.ok) {
        alert("Failed to get the Hosts");
      }
    };

    const eventQuery = `?eventName=${eventName}&hostName=${filterData.hostName}`;
    const getEvents = async () => {
      const eventResponse = await httpContext(`events/filters${eventQuery}`);
      if (eventResponse.ok) {
        const eventResponseData = await eventResponse.json();
        setEventList(eventResponseData);
      }
      if (!eventResponse.ok) {
        alert("Failed to get the Events");
      }
    };
    getHosts();
    getEvents();
  }, [
    eventList.length,
    hostList.length,
    hostName,
    eventName,
    filterData.hostName,
    filterData.eventName,
  ]);

  const hostNameChangeHandler = (event) => {
    setHostName(event.target.value);
  };

  const eventNameChangeHandler = (event) => {
    setEventName(event.target.value);
  };

  const changeFilterHandler = () => {
    dispatch(
      globalFilterActions.setEventFilter({
        eventName: eventNameInputRef.current.value,
      })
    );

    dispatch(
      globalFilterActions.setHostName({
        hostName: hostNameInputRef.current.value,
      })
    );
  };

  const eventValueChangeHandler = (event) => {
    dispatch(
      globalFilterActions.setEventFilter({
        eventId: event.target.value,
        eventName: eventName,
      })
    );
  };

  const hostSelectHandler = (host) => {
    dispatch(
      globalFilterActions.setHostFilter({
        hostId: host.id,
        hostName: host.name,
      })
    );
  };

  const resetFilterHandler = () => {
    dispatch(globalFilterActions.resetFilter(undefined));
  };

  return (
    <>
      <FormControl fullWidth onChange={changeFilterHandler}>
        <Select fullWidth value={filterData.hostId}>
          <FormGroup>
            <TextField
              label="Club / Veranstalter"
              placeholder="Suche nach Club oder Veranstalter"
              defaultValue={filterData.hostName}
              fullWidth
              onChange={hostNameChangeHandler}
            />
          </FormGroup>
          {hostList.map((host, index) => (
            <MenuItem
              key={index}
              value={host.id}
              onClick={() => hostSelectHandler(host)}
            >
              {host.name}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>Filter nach Club / Veranstalter</FormHelperText>
        <Select
          fullWidth
          value={filterData.eventId}
          onChange={eventValueChangeHandler}
        >
          <TextField
            label="Events"
            placeholder="Suche nach Event"
            defaultValue={filterData.eventName}
            fullWidth
            onChange={eventNameChangeHandler}
          />
          {eventList.map((event, index) => (
            <MenuItem
              key={index}
              value={event.eventId}
              onClick={() => setSelectedHostId(event.eventId)}
            >
              {event.eventName}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>Filter nach Event</FormHelperText>
        <div
          style={{
            marginTop: "2rem",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <ButtonOutlined onClick={resetFilterHandler}>
            Reset Filter
          </ButtonOutlined>
          <SubmitButtonFilled>Filter</SubmitButtonFilled>
        </div>
      </FormControl>
    </>
  );
};

export default EventFilter;
