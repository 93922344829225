import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import eventPicture from "../../assets/images/events/event.jpeg";
import BalzAvatar from "../../assets/images/balz.png";
import {
  FacebookIcon,
  FacebookMessengerIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";
import { useHistory } from "react-router-dom";

const EventCard = (props) => {
  const history = useHistory();
  const { event } = props;

  const cardClickHandler = () => {
    history.push(`/events/${event.eventId}`);
  };

  return (
    <Card
      sx={{
        margin: "0 auto 1rem auto",
        width: "90%",
        height: "26rem",
        boxShadow: "2px 2px 8px 3px gray",
        "&:hover": {
          cursor: "pointer",
        },
      }}
    >
      <div
        style={{
          backgroundColor: `rgba(0,0,0,0.3)`,
          position: "absolute",
          width: "90%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Typography variant="h4" sx={{ color: "white", marginTop: "1rem" }}>
          {event.hostName}
        </Typography>
        <Avatar src={BalzAvatar} sx={{ width: 80, height: 80 }} />
      </div>
      <CardMedia
        onClick={cardClickHandler}
        component="img"
        height="200"
        image={eventPicture}
      />
      <CardContent
        onClick={cardClickHandler}
        sx={{
          height: "8rem",
          maxHeight: "10rem",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        <Typography variant="h6" color="secondary" component="div">
          {event.eventName}
        </Typography>
        <Grid
          container
          spacing={3}
          height="100%"
          display="flex"
          justifyContent="center"
        >
          <Grid item container>
            {event.eventDescription}
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <FacebookIcon size={45} round />
          <TwitterIcon size={45} round />
          <WhatsappIcon size={45} round />
          <FacebookMessengerIcon size={45} round />
        </div>
      </CardActions>
    </Card>
  );
};

export default EventCard;
