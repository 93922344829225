import { useEffect, useState } from "react";
import EventCard from "./EventCard";
import { httpContext } from "../../lib/api/httpContext";

const EventList = () => {
  const [eventList, setEventList] = useState([]);

  useEffect(() => {
    const getEvents = async () => {
      const response = await httpContext(`events`);
      if (response.ok) {
        const responseData = await response.json();
        setEventList(responseData);
      }
      if (!response.ok) {
        alert("Failed to laod the Events");
      }
    };
    getEvents();
  }, [eventList.length]);

  return (
    <div style={{ marginTop: "1rem" }}>
      {eventList.map((event, index) => (
        <EventCard key={index} event={event} />
      ))}
    </div>
  );
};

export default EventList;
