import { Redirect, Route, Switch } from "react-router-dom";
import { pageNavigations } from "./page-navigations";

const PageRoutes = () => {
  return (
    <Switch>
      {pageNavigations.map((page, index) => (
        <Route key={index} path={page.link} exact component={page.component} />
      ))}
      <Redirect to="/home">
        <Route path="/" exact />
      </Redirect>
    </Switch>
  );
};

export default PageRoutes;
