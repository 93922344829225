const BASE_URL = "https://euvent.io/api/";
//const BASE_URL = "https://localhost:5001/api/";

export const httpContext = async (apiEndpoint, method, body) => {
  const url = BASE_URL + apiEndpoint;

  switch (method) {
    case "GET":
      return await getRequest(url);
    default:
      return await getRequest(url);
  }
};

const getRequest = async (url) => {
  return await fetch(url, {
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "same-origin",
  });
};
