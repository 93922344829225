import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { httpContext } from "../lib/api/httpContext";

const EventDetailPage = () => {
  const params = useParams();
  const [event, setEvent] = useState();

  useEffect(() => {
    const getEvent = async () => {
      const response = await httpContext(`events/${params.eventId}`);
      if (response.ok) {
        const responseData = await response.json();
        setEvent(responseData);
      }
      if (!response.ok) {
        alert("failed to load the event");
      }
    };
    getEvent();
  }, [params.eventId]);

  if (!event) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <h1>Event Detail Page</h1>
      <h2>{event.eventName}</h2>
    </div>
  );
};

export default EventDetailPage;
