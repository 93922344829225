import { useEffect, useRef, useState } from "react";
import { httpContext } from "../lib/api/httpContext";
import ImageCover from "../assets/images/events/event_cover.jpeg";
import { FormHelperText, MenuItem, Select, TextField } from "@mui/material";
import HomePageFilter from "../components/UI/Filter/HomePageFilter";
import { useDispatch } from "react-redux";
import { globalFilterActions } from "../store/global-filter-slice";
import EventList from "../components/Events/EventList";

const HomePage = () => {
  const dispatch = useDispatch();
  const [cityList, setCityList] = useState([]);
  const [cityQuery, setCityQuery] = useState("");
  const [selectedCityId, setSelectedCityId] = useState("");

  useEffect(() => {
    if (cityQuery.length < 3) {
      return;
    }

    const query = `?cityName=${cityQuery}`;

    const getCities = async () => {
      const response = await httpContext(`cities/filter${query}`);
      if (response.ok) {
        const responseDate = await response.json();
        setCityList(responseDate);
      }
      if (!response.ok) {
        alert("Failed to load the events");
      }
    };
    getCities();
  }, [cityList.length, cityQuery]);

  const cityParamHandler = (event) => {
    setCityQuery(event.target.value);
  };

  const citySelectHandler = (event) => {
    setSelectedCityId(event.target.value);
    dispatch(
      globalFilterActions.setCityFilter({
        cityName: cityQuery,
        cityId: event.target.value,
      })
    );
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          position: "absolute",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "15rem",
        }}
      >
        <div
          style={{
            width: "80%",
            height: "4.5rem",
            borderRadius: "8px",
            backgroundColor: `rgb(236, 236, 236)`,
            padding: "0.5rem",
          }}
        >
          <Select
            variant="outlined"
            fullWidth
            value={selectedCityId}
            onChange={citySelectHandler}
          >
            <TextField
              label="Suche nach PLZ oder Ort"
              fullWidth
              onChange={cityParamHandler}
              type="text"
              helperText="Suche nach Ortschaft"
            />
            {cityList.map((city, index) => (
              <MenuItem
                value={city.id}
                key={index}
                onClick={() => setSelectedCityId(city.id)}
              >
                {city.zipCode} {city.cityName}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>Filter nach Ort oder PLZ</FormHelperText>
        </div>
      </div>
      <img src={ImageCover} alt="Image Cover" height={300} width={"100%"} />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "-5rem",
        }}
      >
        <HomePageFilter />
      </div>

      <EventList />
    </div>
  );
};

export default HomePage;
