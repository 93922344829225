import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  eventName: "",
  eventId: "",
  hostId: "",
  hostName: "",
  cityName: "",
  cityId: "",
  genreId: "",
  dateFrom: new Date().toLocaleDateString("ch-CH"),
  dateTo: new Date("2022-04-10").toLocaleDateString("ch-CH"),
};

const globalFilterSlice = createSlice({
  name: "globalFilter",
  initialState: initialState,
  reducers: {
    setDateRange(state, action) {
      state.dateFrom = action.payload.dateFrom;
      state.dateTo = action.payload.dateTo;
    },
    setEventFilter(state, action) {
      state.eventId = action.payload.eventId;
      state.eventName = action.payload.eventName;
    },
    setHostFilter(state, action) {
      state.hostId = action.payload.hostId;
      state.hostName = action.payload.hostName;
    },
    setCityFilter(state, action) {
      state.cityName = action.payload.cityName;
      state.cityId = action.payload.cityId;
    },
    setGenreId(state, action) {
      state.genreId = action.payload.genreId;
    },
    resetFilter(state) {
      state.eventName = "";
      state.eventId = "";
      state.hostId = "";
      state.hostName = "";
      state.cityName = "";
      state.cityId = "";
      state.genreId = "";
    },
  },
});

export const globalFilterActions = globalFilterSlice.actions;

export default globalFilterSlice;
