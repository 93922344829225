import HomePage from "../pages/HomePage";
import AboutUsPage from "../pages/AboutUsPage";
import EventDetailPage from "../pages/EventDetailPage";

export const pageNavigations = [
  { name: "Home", link: "/home", component: HomePage },
  {
    name: "Event Detail",
    link: "/events/:eventId",
    component: EventDetailPage,
  },
  { name: "About Us", link: "/aboutUs", component: AboutUsPage },
];
