import { createTheme } from "@mui/material";

const primaryColor = "#b777b2";
const secondaryColor = "#e87fa7";
const tertiaryColor = "#ff9092";
const neutralColor = "#ffe6ff";

export const theme = createTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    tertiary: {
      main: tertiaryColor,
    },
    neutral: {
      main: neutralColor,
    },
  },
});
