import { styled } from "@mui/material";

export const SubmitButtonFilled = styled("button")(({ theme }) => ({
  color: "white",
  width: "9rem",
  height: "3rem",
  fontSize: "1.5rem",
  backgroundColor: theme.palette.primary.main,
  borderRadius: "10px",
  border: "none",
  //boxShadow: "2px 2px 8px 3px gray",
  "&:hover": {
    cursor: "pointer",
  },
}));

export const ButtonOutlined = styled("button")(({ theme }) => ({
  color: theme.palette.primary.main,
  width: "9rem",
  height: "3rem",
  fontSize: "1.2rem",
  borderRadius: "10px",
  border: "none",
  textDecoration: "underline",
  "&:hover": {
    cursor: "pointer",
  },
}));
