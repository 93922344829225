import { AppBar, Avatar, Box, Tab, Tabs, Toolbar } from "@mui/material";
import { useState } from "react";

const NavigationBar = () => {
  const [tabValue, setTabValue] = useState(0);

  const tabChangeHandler = (event, tabValue) => {
    setTabValue(tabValue);
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="fixed"
        color="neutral"
        sx={{ padding: "1rem 0 1rem 0" }}
      >
        <Toolbar>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              width: "100%",
            }}
          >
            <Tabs
              value={tabValue}
              onChange={tabChangeHandler}
              indicatorColor="primary"
            >
              <Tab
                disableTouchRipple
                disableRipple
                disableFocusRipple
                value={0}
                label="Events"
              />
              <Tab
                disabled
                disableTouchRipple
                disableRipple
                disableFocusRipple
                value={1}
                label="Clubs/Veranstalter"
              />
            </Tabs>
          </div>
          <Avatar>JH</Avatar>
        </Toolbar>
      </AppBar>
      <div style={{ marginTop: "5.7rem" }} />
    </Box>
  );
};

export default NavigationBar;
