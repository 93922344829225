import { Tab, Tabs } from "@mui/material";
import { useState } from "react";
import EventFilter from "./EventFilter";

const HomePageFilter = () => {
  const [filterTab, setFilterTab] = useState(0);

  const tabChangeHandler = (event, tabValue) => {
    setFilterTab(tabValue);
  };

  return (
    <div
      style={{
        width: "80%",
        height: "20rem",
        backgroundColor: `rgb(236, 236, 236)`,
        padding: "0 1rem 1rem 1rem",
        borderRadius: "8px",
        boxShadow: "2px 2px 8px 3px gray",
      }}
    >
      <Tabs
        value={filterTab}
        onChange={tabChangeHandler}
        textColor="secondary"
        indicatorColor="secondary"
      >
        <Tab value={0} label="Events" />
        <Tab value={1} disabled label="Clubs" />
        <Tab value={2} disabled label="Genre" />
      </Tabs>
      <div style={{ marginTop: "1rem" }}>
        {filterTab === 0 && <EventFilter />}
        {filterTab === 1 && <h1>Hello Host</h1>}
        {filterTab === 2 && <h1>Hello Genre</h1>}
      </div>
    </div>
  );
};

export default HomePageFilter;
