import PageRoutes from "./routes/PageRoutes";
import NavigationBar from "./components/UI/Navigations/NavigationBar";

function App() {
  return (
    <div>
      <NavigationBar />
      <PageRoutes />
    </div>
  );
}

export default App;
