import { configureStore } from "@reduxjs/toolkit";
import globalFilterSlice from "./global-filter-slice";

const store = configureStore({
  reducer: {
    globalFilter: globalFilterSlice.reducer,
  },
});

export default store;
